import {
  always,
  either,
  pipe,
  prop,
} from 'ramda'
import { createRouteBundle, createSelector } from 'redux-bundler'

import queryString from 'query-string'

import createLogger from '~/src/Lib/Logging'
import { debounce, EMPTY_OBJECT, shallowEquals } from '~/src/Lib/Utils'

import routes from './routes'

const EMPTY_ROUTE_INFO = { params: EMPTY_OBJECT, value: null }

export const OPEN_DIALOG = 'OPEN_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'
export const SET_DIALOG_ENTITY = 'SET_DIALOG_ENTITY'
export const CLEAR_DIALOG_ENTITY = 'CLEAR_DIALOG_ENTITY'

const logger = createLogger('Dialog/bundle')

const defaultState = Object.freeze({ open: 0, entity: null })
const debouncedDoOpen = debounce(({ dispatch }) => dispatch({ type: OPEN_DIALOG }), 150, true)
const debouncedDoClose = debounce(({ dispatch }) => dispatch({ type: CLOSE_DIALOG }), 150, true)

const {
  selectRouteMatcher: selectDialogRouteMatcher,
  selectRoutes: selectDialogRoutes,
} = createRouteBundle(routes, { routeInfoSelector: 'selectHash' })

export const mainBundle = {
  name: 'dialog',
  reducer: (state = defaultState, action = {}) => {
    switch (action.type) {
      case OPEN_DIALOG:
        return { ...state, open: state.open + 1 }
      case CLOSE_DIALOG:
        return { ...state, open: Math.max(0, state.open - 1) }
      default:
        return state
    }
  },
  selectDialog: prop('dialog'),
  selectDialogIsOpen: createSelector('selectDialog', pipe(prop('open'), Boolean)),
  doOpenDialog: () => debouncedDoOpen,
  doCloseDialog: () => debouncedDoClose,
}

export default {
  ...mainBundle,
  selectDialogRouteMatcher,
  selectDialogRoutes,
  selectDialogRouteInfo: createSelector('selectHash', 'selectDialogRouteMatcher', (hash, matcher) => {
    const match = matcher(hash)
    if (!match) return EMPTY_ROUTE_INFO
    const { value: { params } } = match

    return {
      ...match,
      params: {
        ...match?.params,
        ...params,
      }
    }
  }),
  selectDialogRouteParams: createSelector(
    'selectDialogRouteInfo',
    either(prop('params'), always(EMPTY_OBJECT))
  ),
  selectDialogQueryObject: createSelector('selectDialogRouteInfo', ({ url }) => {
    if (url && url.match(/\?\w+/)) {
      return queryString.parse(url.slice(url.indexOf('?')))
    }
    return EMPTY_OBJECT
  }),
  doUpdateDialogQuery: params => ({ store }) => {
    const { hash } = store.selectUrlObject()
    if (!hash) {
      logger.error('Cannot update dialog query without a hash')
      return
    }
    const oldParams = store.selectDialogQueryObject()
    if (shallowEquals(oldParams, params, 2)) return
    const [url] = hash.split('?')
    store.doUpdateHash(`${url}?${queryString.stringify(params)}`)
  },
  selectDialogRoute: createSelector(
    'selectDialogRouteInfo',
    either(prop('value'), always(null))
  ),
  doCloseDialogRoute: () => ({
    actionCreator: 'doUpdateHash',
    args: ['', { maintainScrollPosition: true }]
  }),
}
